var exports = {};

exports = function (hljs) {
  return {
    contains: [{
      className: "meta",
      begin: /^([\w.-]+|\s*#_)?=>/,
      starts: {
        end: /$/,
        subLanguage: "clojure"
      }
    }]
  };
};

export default exports;